function colorProgressBar() {
  var progress = document.querySelectorAll("progress");
  var maxValueFromPage = parseInt(progress[0].getAttribute("max"));
  var valueFromPage;
  var percentToColor
  for (var i = 0; i < progress.length; i++) {
    valueFromPage = parseInt(progress[i].getAttribute("value"));

    if (valueFromPage == 0) {
      progress[i].style.setProperty("--progress-background-color", "rgb(245,10,10)");
    } else {
      progress[i].style.setProperty("--progress-background-color", "#CCC");
    }

    if (valueFromPage > maxValueFromPage) {
      valueFromPage = maxValueFromPage;
    }

    percentToColor = valueFromPage * 100 / maxValueFromPage;

    if (percentToColor < 25) {
      progress[i].style.setProperty("--progress-value-color", "rgb(245,10,10)");
    } else if (percentToColor < 50) {
      progress[i].style.setProperty("--progress-value-color", "rgb(245,120,10)");
    } else if (percentToColor < 75) {
      progress[i].style.setProperty("--progress-value-color", "rgb(220,220,10)");
    } else if (percentToColor < 100) {
      progress[i].style.setProperty("--progress-value-color", "rgb(190,255,10)");
    } else if (percentToColor === 100) {
      progress[i].style.setProperty("--progress-value-color", "rgb(60,255,60)");
    }
  }
}
